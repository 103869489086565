<template>
  <div class="container">
    <div class="title">
      <div class="back" @click="back"><i class="el-icon-arrow-left"></i></div>
      <div class="title-txt">{{ $route.meta.title }}</div>
    </div>
    <div class="main-content">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件 -->
        </router-view>
      </keep-alive>

      <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件 -->
      </router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
  mounted() {
    this.$bridge.registerhandler("showAlert", (data) => {
      //接收原生回传过来的值 data
      alert(data);
    });
  },

  methods: {
    back() {
      this.$router.back();
      if (this.$route.name === "vip_index") {
        this.callNative();
      } else if (this.$route.name === "vip_pay") {
        this.$utils.setLs("isShowDone", false);
      }
    },

    async callNative() {
      try {
        this.$bridge.callhandler("closeWebView", {}, () => {});
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title {
    height: 60px;
    display: flex;

    .back {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        font-weight: 700 !important;
        font-size: 22px;
      }
    }
    .title-txt {
      flex: 1;
      margin-right: 60px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 18px;
    }
  }
  .main-content {
    flex: 1;
    overflow: auto;
  }
}
</style>
